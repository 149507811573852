import { PageProps } from 'gatsby';
import React, { FC, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { NoIndex, description, title } from '@components/layout/SEO';
import { Mypage } from '@features/mypage';
import { Item } from '@features/mypage/History/Item';

const ItemPage: FC<PageProps> = ({ params }) => {
  const id = parseInt(params.id);
  return (
    <Layout>
      <Helmet>
        <title>マイページ | 購入履歴詳細 | {title}</title>
        <meta name="description" content={description} />
        {NoIndex}
      </Helmet>
      <Suspense fallback={<p>Loading...</p>}>
        <Mypage>
          <Item id={id} />
        </Mypage>
      </Suspense>
    </Layout>
  );
};

export default ItemPage;
