import React, { FC } from 'react';

import { Block } from '@components/layout/block';
import { Card } from '@components/layout/card';
import { Flex } from '@components/layout/flexbox';
import { Grid } from '@components/layout/grid';
import { Heading2 } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { Span } from '@components/typographies/span';
import { FormattedDate } from '@features/mypage/History/components/FormatedDate';
import { OrderItemImage } from '@features/mypage/History/components/OrderItemImage';
import { Price } from '@features/mypage/History/components/Price';
// import { ShippingStatusSteps } from '@features/mypage/History/components/ShippingStatusSteps';
import { useDetectMedia } from '@hooks/useDetectMedia';
import useOrder from '@hooks/useOrder';

export const Item: FC<{ id: number }> = ({ id }) => {
  const { order } = useOrder(id);
  const { md } = useDetectMedia();

  if (!order) {
    return null;
  }
  return (
    <Block width="100%" paddingLeft={md ? '33px' : '0px'} paddingBottom={md ? '0px' : '20px'}>
      <Heading2 fontSize={md ? '24px' : '16px'}>購入履歴</Heading2>
      <Block padding={'16px 0'}>
        <Card px="12px" py="20px" width="100%" border="1.4px solid #d9d9d9" radius="5px" bg="white">
          <Block borderBottom="1.4px solid #d9d9d9" padding="0 0 30px 0">
            <Grid gridTemplateColumns="130px 1fr" gap={md ? '16px' : '13px'}>
              <Block width="130px" height="130px">
                <OrderItemImage src={order.item?.url} alt={order.item?.name} />
              </Block>
              <Block width="100%">
                <Block borderBottom="1px solid var(--color-gray-400)" padding="0 0 10px 0">
                  <Flex>
                    <Block width="100%">
                      <Grid gridTemplateColumns={md ? '120px 1fr' : '1fr'} gap="10px">
                        {/* {!md && (
                          <Block width="fit-content">
                            <ShippingStatusSteps status={order.shippingStatus} />
                          </Block>
                        )} */}
                        <FormattedDate title={'注文日'} dateString={order.purchaseDate} />
                        <Paragraph color="gray-900" lh="24px">
                          {`お届け先：`}
                          <Span
                            color="var(--color-gray-900)"
                            fontSize="14px"
                            lineHeight="24px"
                            borderBottom="1px solid var(--color-gray-900)"
                          >
                            {order.shippingAddress?.title}
                          </Span>
                        </Paragraph>
                      </Grid>
                      <FormattedDate title={'お届け指定日'} dateString={order.expectDate} />
                    </Block>
                    {/* {md && <ShippingStatusSteps status={order.shippingStatus} />} */}
                  </Flex>
                </Block>
                <Block paddingTop={md ? '10px' : '15px'}>
                  <Flex direction="column" alignItems="flex-start" gap="8px">
                    <Price width="max-content" amount={order.bill?.amount} fontWeight="700" />
                    <Paragraph lh="18px">{order.item?.name}</Paragraph>
                    <Paragraph lh="18px" color="gray-900">
                      {order.shop?.name}
                    </Paragraph>
                  </Flex>
                </Block>
              </Block>
            </Grid>
          </Block>
          <Block
            borderBottom="1.4px solid var(--color-gray-400)"
            padding={md ? '15px 0 30px 0' : '15px 0 10px 0'}
          >
            <Paragraph fontSize="16px" fontWeight="700" lh="16px">
              注文内容
            </Paragraph>
            <Flex
              gap="50px"
              alignItems="flex-start"
              jc=" flex-start"
              direction={md ? 'row' : 'column'}
              margin="15px 0 0 0"
            >
              <Block>
                <Block paddingBottom="12px">
                  <Paragraph fontSize="14px" fontWeight="700" lh="14px">
                    お届け先情報
                  </Paragraph>
                </Block>
                <Paragraph lh="20px">{`〒${order.shippingAddress?.zipcode || ''}`}</Paragraph>
                <Paragraph lh="20px">
                  {order.shippingAddress?.prefecture}
                  {order.shippingAddress?.municipality}
                </Paragraph>
                <Paragraph lh="20px">
                  {order.shippingAddress?.address1}
                  {order.shippingAddress?.address2}
                </Paragraph>
                <Paragraph lh="20px">{`${order.shippingAddress?.title || ''} 様`}</Paragraph>
              </Block>
              <Block>
                <Block paddingBottom="12px">
                  <Paragraph fontSize="14px" fontWeight="700" lh="14px">
                    お支払い方法
                  </Paragraph>
                </Block>
                <Paragraph lh="20px">クレジットカード一括払い</Paragraph>
              </Block>
              <Block>
                <Block paddingBottom="12px">
                  <Paragraph fontSize="14px" fontWeight="700" lh="14px">
                    領収書/購入明細書
                  </Paragraph>
                </Block>
                <Price title={'商品の小計'} amount={order.bill?.billAmount?.orderSubtotal?.item} />
                <Price
                  title={'配送料・手数料'}
                  amount={order.bill?.billAmount?.orderSubtotal?.shippingFee}
                />
                <Price title={'注文合計'} amount={order.bill?.billAmount?.orderAmount} />
                <Price title={'ご請求額'} amount={order.bill?.amount} />
                {/* FIXME: href を付け足す */}
                {/* <Block paddingTop="12px" display="flex" justifyContent="flex-end">
                  <Anchor color="black" fontSize="14px" cursor="pointer">
                    領収書発行
                  </Anchor>
                </Block> */}
              </Block>
            </Flex>
          </Block>
          <Block paddingTop="15px">
            <Paragraph fontSize="16px" fontWeight="700" lh="16px">
              配送業者
            </Paragraph>
            <Block paddingTop="15px">
              <Paragraph lh="20px">{order.shippingOption?.name || '-'}</Paragraph>
              <Paragraph lh="20px">
                {`追跡番号: ${order.shippingOption?.trackingCode || '商品の発送までお待ち下さい'}`}
              </Paragraph>
              {/* {
              order.shippingOption?.trackingCode &&
              <Paragraph lh="20px">
              追跡はこちら
              </Paragraph>
            } */}
            </Block>
          </Block>
        </Card>
      </Block>
    </Block>
  );
};
